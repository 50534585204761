import {Container, ContentLayout, Header} from "@cloudscape-design/components";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Helmet} from 'react-helmet';
import {useContext, useEffect} from "react";
import {UserProfile} from "../context/UserProfile";
import {useCookies} from "react-cookie";

// import {Link} from "react-router-dom";
export default function Auth() {
    const [searchParams] = useSearchParams();
    const {userProfile, setUserProfile} = useContext(UserProfile);
    const navigate = useNavigate();

    const [, setCookie] = useCookies(["user"]);

    useEffect(() => {
        // Update the document title using the browser API
        const profile = {userId: searchParams.get("id")};
        setUserProfile(profile);
        setCookie("user", profile, { path: "/" });
        navigate('/chats');
    }, [searchParams, setUserProfile]);

    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Authentication
                </Header>
            }
        >
            <Helmet>
                <title>Authentication</title>
            </Helmet>
            <Container
                header={
                    <Header variant="h2" description="Telegram login widget is used to authorize chat admins.">
                        Telegram Login
                    </Header>
                }
            >
                <div className="contentPlaceholder">
                    <p>{`First name: ${userProfile.userId}`}</p>
                    <p>You will be automatically redirected:</p>
                    <Link to={`/chats`}>Your chats</Link>
                </div>
            </Container>
        </ContentLayout>
    )
}