import {
    Container,
    ContentLayout,
    Header,
} from "@cloudscape-design/components";
import TextContent from "@cloudscape-design/components/text-content";
import Link from "@cloudscape-design/components/link";
import {Helmet} from "react-helmet";

export default function Help() {
    return (
        <ContentLayout header={<Header variant="h1">Protectron Bot</Header>}>
            <Helmet>
                <title>ProtectronBot</title>
            </Helmet>
            <Container>
                <TextContent>
                    <h2>How to use @ProtectronBot</h2>
                    <p>
                        Hello, this tutorial will describe how to install @protectronbot in
                        your group and configure it.
                    </p>
                    <p>
                        Brief explanations will also be given on the operation of all its
                        functions. In case you did not find the answer to your question -
                        you can contact our support chat{" "}
                        <Link href="https://t.me/antispamchat">
                            https://t.me/antispamchat
                        </Link>
                    </p>
                    <p>
                        <strong>
                            First of all, you need to add the bot to the group. There are
                            several ways to do this:
                        </strong>
                    </p>
                    <ol>
                        <li>Go to group settings, select add user;</li>
                        <li>
                            Go into the bot, press start, in the message that the bot will
                            send select "Add ProtectronBot to your group";
                        </li>
                        <li>
                            On the page "Bot Info" click on the button "Add to Group" and
                            select your group.
                        </li>
                    </ol>
                    <p>
                        Next, you must{" "}
                        <strong>give the bot administrator privileges</strong> (to delete
                        messages and ban users).{" "}
                    </p>
                    <p>
                        Thereafter you must enter the <strong>/start</strong> command in the
                        chat in order for the bot to start working.
                    </p>
                    <p>
                        You can change the settings with the <strong>/status</strong>{" "}
                        command
                    </p>
                    <p>
                        If you did everything correctly, then the message that displays the
                        bot in the status should be three icons. If the first icon is not
                        green - you forgot to make the bot an administrator. In case the
                        second and third icon is not green - you forgot to give the right to
                        delete messages and ban users accordingly. Administrator rights are
                        set in your group setup. If you cannot install them, you can contact
                        our support team.
                    </p>
                    <p>
                        In the message that appears as a result of the command /status, the
                        bot functions are displayed. Now let's talk in more detail about
                        them.
                    </p>
                    <h2>ANTISPAM</h2>
                    <p>
                        <strong>/antispam</strong> - the function deletes messages
                        containing unwanted ads. The bot uses a neural network to classify
                        messages that are related to spam, therefore, compared to other bots
                        there are no settings. This allows you to configure the bot very
                        quickly.
                    </p>
                    <p>
                        <strong>/antispam_mode</strong> - the function forces the bot to
                        check messages of all users, including those who have earned
                        credibility for a long time. If the bot does a poor job spam
                        filtering - enable this feature.
                    </p>
                    <p>
                        In order to unlock the user - you need to use the command{" "}
                        <strong>/unban @username</strong>
                    </p>
                    <p>
                        Or comment on the message of this user with the command{" "}
                        <strong>/unban</strong>
                    </p>
                    <p>
                        In case you need to block the user, comment on his message with the{" "}
                        <strong>/ban</strong> command.
                    </p>
                    <p>
                        Or you can use the command <strong>/ban @username</strong>
                    </p>
                    <p>
                        If the bot missed some kind of advertisement, comment on the message
                        with the advertisement with the <strong>/report</strong> command.
                        The message will be deleted, and we will further train the bot on
                        the messages that you flag.
                    </p>
                    <h2>IMAGEFILTER</h2>
                    <p>
                        <strong>/imagefilter</strong> - Enabling this feature will protect
                        your group from publishing inappropriate content for which your
                        group may be blocked in case of complaints about it. Photos are
                        inappropriate content erotic nature, nude people and photos bearing
                        sexual content. We do not use third-party services, so you can be
                        sure that the photos from your group do not get somewhere to third
                        parties.
                    </p>
                    <h2>NOEVENTS</h2>
                    <p>
                        <strong>/noevents</strong> - this function forces the bot to delete
                        all service messages from telegrams that someone entered or left the
                        group. If you have a large group, we recommend that you enable this
                        feature immediately, so that these messages did not distract or
                        reach you.
                    </p>
                    <h2>NOLINKS and other functions</h2>
                    <p>
                        <strong>/nolinks</strong> - completely forbids all users (except
                        administrators) to publish any links in the group. The following
                        functions are very similar to each other, but they block various
                        content in messages.
                    </p>
                    <p>Therefore, we will not describe them in detail: </p>
                    <p>
                        <strong>/noforwards</strong> - blocks forwarded messages;
                    </p>
                    <p>
                        <strong>/nolocations</strong> - blocks messages containing
                        locations;
                    </p>
                    <p>
                        <strong>/nocontacts</strong> - blocks messages containing user
                        contacts;
                    </p>
                    <p>
                        <strong>/nocommands</strong> - blocks messages containing commands
                        to bots;
                    </p>
                    <p>
                        <strong>/nohashtags</strong> - blocks messages containing hashtags;
                    </p>
                    <p>
                        <strong>/novoice</strong> - blocks voice messages.
                    </p>
                    <h2>WARNINGBANS</h2>
                    <p>
                        <strong>/warningbans</strong> is a function to block users in case
                        they repeatedly violate the rules of the group. The bot takes into
                        account user violations in all groups where it is installed - so you
                        need to use it carefully this function. She can immediately block
                        users who are just logged into your group and allowed any violation.
                    </p>
                    <p>
                        If the user needs to be unblocked, use the command{" "}
                        <strong>/unban</strong> @username, or comment his message with{" "}
                        <strong>/unban</strong>
                    </p>
                    <h2>NOBOTS</h2>
                    <p>
                        <strong>/nobots</strong> - the bot will check all users added by
                        users of your group in case if an attempt to add a bot is accepted,
                        it will be deleted immediately. It is worth paying attention that
                        bots do not see messages of other bots - therefore, it is better to
                        immediately prevent hits bots in your chat. If they are already
                        there, you will have to delete them manually.
                    </p>
                    <h2>ANTIFLOOD</h2>
                    <p>
                        <strong>/antiflood</strong> - in case your users often flood -
                        enable this function. Messages of users who often write will be
                        deleted, and a message will be published in the group about that
                        flood is prohibited. If the <strong>/warningbans</strong> function
                        is enabled, users can be banned in case they have frequent
                        violations.
                    </p>
                    <h2>PROFANITY</h2>
                    <p>
                        <strong>/profanity</strong> - all messages published in the group
                        will be checked for the content of obscene words and obscene
                        expressions. So far, only English is supported. If the function{" "}
                        <strong>/warningbans</strong> is enabled - users can be banned if
                        they have frequent violations.
                    </p>
                    <h2>BLACKLIST and WHITELIST</h2>
                    <p>
                        <strong>/blacklist</strong> - blacklist. Allows you to block
                        messages containing unwanted words, links and stickerpacks. In case
                        you need to block a sentence containing a "word", you need to add
                        this word to the black list without quotes. If it is added in
                        quotation marks, then the message will be blocked only if it is
                        complete matches with this word. Also in quotation marks indicate
                        the name of sticker packs.
                    </p>
                    <p>
                        <strong>/blacklist_add word1, word2, word3</strong> - adds 3 words
                        to the black list. You can add expressions. As a delimiter comma is
                        used.
                    </p>
                    <p>
                        <strong>/blacklist_remove word2</strong> - removes word2 from the
                        list.
                    </p>
                    <p>
                        <strong>/blacklist_clear</strong> - clears the list completely
                    </p>
                    <p>
                        <strong>/whitelist</strong> - whitelist. Work with a list similar to
                        black. You can add the name of the channels to it, or channel links.
                        t.me/link_to_chanel - only link_to_chanel should be added to the
                        list
                    </p>
                    <h2>WELCOME. Greeting new users.</h2>
                    <p>
                        <strong>/welcome</strong> - the function allows you to view the
                        message to greet new users.
                    </p>
                    <p>
                        <strong>/welcome_set</strong> - the function allows you to set a new
                        greeting message. You can use greetings on several lines. To jump to
                        a new line, use ctrl + enter. You can use links, for this you must
                        enter them in the following format: [link text](http://google.com/)
                    </p>
                    <p>
                        To set a greeting, use <strong>/welcome_set hello !</strong>
                    </p>
                    <p>
                        After enabling this function, when a new user logs in, the following
                        message will be displayed: <br/>
                        <br/>
                        <p>
                            <code>Protectron</code>
                        </p>
                        <p>
                            <code>Nickname, hello!</code>
                        </p>
                    </p>
                    <p>I hope the quick guide has been helpful to you.</p>
                </TextContent>
            </Container>
        </ContentLayout>
    );
}
