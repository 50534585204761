import {
    Container,
    ContentLayout,
    Header,
} from "@cloudscape-design/components";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Auth() {
    return (
        <ContentLayout header={<Header variant="h1">Protectron Bot</Header>}>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Something went wrong. The page address you entered does not exist."
                    >
                        Error 404 (Not Found)
                    </Header>
                }
            >
                <Helmet>
                    <title>Error 404 (Not Found)</title>
                </Helmet>
                <div className="contentPlaceholder">
                    <p>
                        Please go to the main page using the link below, or enter the
                        correct address.
                    </p>
                    <Link to={`/`}>Main page</Link>
                </div>
            </Container>
        </ContentLayout>
    );
}
