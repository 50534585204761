import {AppLayout, BreadcrumbGroup, Flashbar, HelpPanel, SideNavigation} from "@cloudscape-design/components";
import {Outlet} from "react-router-dom";
import React from "react";

export default function BotLayout() {
    const [isNavigationOpen, setNavigationOpen] = React.useState(false);
    const [isToolsOpen, setToolsOpen] = React.useState(false);

    return (
        <AppLayout
            breadcrumbs={
                <BreadcrumbGroup
                    items={[
                        {text: 'Home', href: '/'},
                        // {text: 'Chats', href: '/app/chats'},
                        // {text: 'Data Science Chat', href: '/chats/123'},
                    ]}
                />
            }
            navigationOpen={isNavigationOpen}
            onNavigationChange={({detail}) => {
                setNavigationOpen(detail.open);
            }}
            navigation={
                <SideNavigation
                    header={{
                        href: '#',
                        text: 'ProtectronBot',
                    }}
                    items={
                        [
                            {type: 'link', text: `ProtectronBot`, href: "https://t.me/ProtectronBot"},
                            {type: 'link', text: `ProtectronBot Chat`, href: "https://t.me/antispamchat"}
                        ]
                    }
                />
            }
            notifications={
                <Flashbar
                    items={[]}
                    // items={[
                    //     {
                    //         type: 'info',
                    //         dismissible: true,
                    //         content: 'This is an info flash message.',
                    //         id: 'message_1',
                    //     },
                    // ]}
                />
            }
            toolsOpen={isToolsOpen}
            tools={
                <HelpPanel header={<h2>Overview</h2>}>
                    <p>What ProtectronBot can do:</p>
                    <ul>
                        <li>Remove spam links, shortened urls, external mentions, forwards, reply keyboard links,
                            unwanted advertising.
                        </li>
                        <li>Hide user join and left messages.</li>
                        <li>Allow to blacklist words and domains.</li>
                        <li>Remove profanity and flood messages.</li>
                        <li>Restrict permissions for spammers.</li>
                        <li>Stop members from adding spam bots to your group.</li>
                    </ul>

                    <p>How to start using bot?</p>
                    <ol>
                        <li>Add <a href="https://telegram.me/ProtectronBot?startgroup=true">ProtectronBot</a> to your
                            group.
                        </li>
                        <li>Assign admin permissions (<strong>delete messages, ban users</strong>).</li>
                        <li>Run <strong>/start</strong> command in the group.</li>
                        <li>Check settings using <strong>/status</strong>.</li>
                    </ol>
                </HelpPanel>
            }
            onToolsChange={({detail}) => {
                setToolsOpen(detail.open)
            }}
            content={<Outlet/>}
        />
    )
}