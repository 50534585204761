import React, {useEffect, useMemo, useState} from 'react';
// import './App.css';
import {AppLayout, BreadcrumbGroup, Flashbar, HelpPanel, SideNavigation} from '@cloudscape-design/components';
import {I18nProvider} from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";
import {Outlet} from "react-router-dom";
import ReactGA from 'react-ga4';
import BotLayout from "./BotLayout";
import {UserProfile} from "./context/UserProfile";
import {CookiesProvider, useCookies} from "react-cookie";

function App() {
    const LOCALE = 'en';
    const [userProfile, setUserProfile] = React.useState({});
    const [cookies, setCookie] = useCookies(["user"]);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname, title: window.location.search});
    }, []);

    useEffect(() => {
        if (cookies.user) {
            setUserProfile(cookies.user);
        }
    }, [cookies.user]);

    return (
        <I18nProvider locale={LOCALE} messages={[messages]}>
            <CookiesProvider>
                <UserProfile.Provider value={{userProfile, setUserProfile}}>
                    <BotLayout/>
                </UserProfile.Provider>
            </CookiesProvider>
        </I18nProvider>
    );
}

export default App;
