import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import "@cloudscape-design/global-styles/index.css";
import "@cloudscape-design/global-styles/dark-mode-utils.css";
import App from "./App";
import {applyMode, Mode} from "@cloudscape-design/global-styles";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Chat from "./containers/Chat";
import Chats from "./containers/Chats";
import Login from "./containers/Login";
import Auth from "./containers/Auth";
import Help from "./containers/Help";
import Error from "./containers/Error";
import ReactGA from 'react-ga4';
// import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-YC36MRDQ0S");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <App/>,
            children: [
                {
                    index: true,
                    element: <Help/>,
                },
                {
                    path: "login",
                    element: <Login/>,
                },
                {
                    path: "auth",
                    element: <Auth/>,
                },
                {
                    path: "chats",
                    element: <Chats/>,
                },
                {
                    path: "chats/:chatId",
                    element: <Chat/>,
                },
                {
                    path: "*",
                    element: <Error/>
                }
            ],
        },
    ]
);

applyMode(Mode.Dark);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
