import {useEffect} from "react";
import {Container, ContentLayout, Header} from "@cloudscape-design/components";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Login() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?22';
        script.async = true;
        script.setAttribute('data-telegram-login', "ProtectronBot");
        script.setAttribute('data-size', "large");
        script.setAttribute('data-auth-url', "https://protectronbot.com/auth");
        script.setAttribute('data-request-access', "write");
        const telegramLoginBtn = document.querySelector("#telegram-login-btn");
        if (telegramLoginBtn) {
            telegramLoginBtn.appendChild(script);
        }

        return () => {
            const telegramLoginBtn = document.querySelector("#telegram-login-btn");
            if (telegramLoginBtn) {
                telegramLoginBtn.removeChild(script);
            }
        };
    }, []);

    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Protectron Bot
                </Header>
            }
        >
            <Helmet>
                <title>Login page</title>
            </Helmet>
            <Container
                header={
                    <Header variant="h2" description="You must be logged in to log in.">
                        Please login
                    </Header>
                }
            >
                <div className="contentPlaceholder">
                    <p>Click for login:</p>
                    <div id="telegram-login-btn"/>
                    <Link to={`chats`}>Your chats</Link>
                    <p>If you have any questions, you can find answers on the <Link to={`help`}>help page.</Link></p>
                </div>
            </Container>
        </ContentLayout>
    )
}