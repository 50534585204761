import React, {createContext} from "react";

interface UserProfileType {
    userId?: string;
    first_name?: string;
    last_name?: string;
    photo_url?: string;
    auth_date?: string;
    hash?: string;
}

export const UserProfile =
    createContext<{ userProfile: UserProfileType, setUserProfile: React.Dispatch<React.SetStateAction<{}>> }>({
        userProfile: {}, setUserProfile: () => {
        }
    });