import {
    Button, Checkbox,
    Container,
    Form,
    FormField,
    Header, Input, RadioGroup,
    SpaceBetween, Textarea
} from "@cloudscape-design/components";
import React from "react";
import {Helmet} from 'react-helmet';

export default function Chat() {
    const [isAntispam, setAntispam] = React.useState(false);
    const [antispamMode, setAntispamMode] = React.useState("Simple");
    const [isNoEvents, setNoEvents] = React.useState(false);
    const [isNoBots, setNoBots] = React.useState(false);
    const [isNoLinks, setNoLinks] = React.useState(false);
    const [isNoForwards, setNoForwards] = React.useState(false);
    const [isNoContacts, setNoContacts] = React.useState(false);
    const [isNoLocations, setNoLocations] = React.useState(false);
    const [isNoCommands, setNoCommands] = React.useState(false);
    const [isNoHashtags, setNoHashtags] = React.useState(false);
    const [isAntiflood, setAntiflood] = React.useState(false);
    const [isImageFilter, setImageFilter] = React.useState(false);
    const [isProfanity, setProfanity] = React.useState(false);
    const [blacklist, setBlacklist] = React.useState("");
    const [whitelist, setWhitelist] = React.useState("");

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Helmet>
                <title>Chat page</title>
            </Helmet>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link">
                            Cancel
                        </Button>
                        <Button variant="primary">Submit</Button>
                    </SpaceBetween>
                }
                header={<Header variant="h1">Data Science Chat</Header>}
            >
                <Container
                    header={
                        <Header variant="h2">
                            Chat settings
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Spam">
                            <Checkbox
                                onChange={({detail}) =>
                                    setAntispam(detail.checked)
                                }
                                checked={isAntispam}
                                description="Filter unwanted advertising and restrict spammers."
                            >
                                Enable Antispam
                            </Checkbox>
                        </FormField>

                        <FormField label="Antispam Mode">
                            <RadioGroup
                                onChange={({detail}) => setAntispamMode(detail.value)}
                                value={antispamMode}
                                items={[
                                    {
                                        value: "Simple",
                                        label: "Simple mode",
                                        description: "Checks messages from new members only.",
                                        disabled: !isAntispam
                                    },
                                    {
                                        value: "Advanced",
                                        label: "Strict mode",
                                        description: "Checks all messages.",
                                        disabled: !isAntispam
                                    }
                                ]}
                            />
                        </FormField>

                        <FormField label="Join and Left Notifications">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoEvents(detail.checked)
                                }
                                checked={isNoEvents}
                                description='Filter "X joined or left the group" notifications.'
                            >
                                Enable Events Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Bot Invites">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoBots(detail.checked)
                                }
                                checked={isNoBots}
                                description="Don't allow users to invite bots."
                            >
                                Enable Bot Invite Protection
                            </Checkbox>
                        </FormField>

                        <FormField label="Links">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoLinks(detail.checked)
                                }
                                checked={isNoLinks}
                                description='Filter messages with links, mentions of unknown members, forwards, reply markup.'
                            >
                                Enable Links Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Message Forwarding">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoForwards(detail.checked)
                                }
                                checked={isNoForwards}
                                description='Filter messages forwarded from other groups or users.'
                            >
                                Enable Forwards Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Contacts">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoContacts(detail.checked)
                                }
                                checked={isNoContacts}
                                description='Filter messages with contact numbers of users.'
                            >
                                Enable Contacts Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Locations">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoLocations(detail.checked)
                                }
                                checked={isNoLocations}
                                description='Filter messages containing user locations.'
                            >
                                Enable Locations Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Bot Commands">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoCommands(detail.checked)
                                }
                                checked={isNoCommands}
                                description='Filter commands from group members.'
                            >
                                Enable Commands Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Hashtags">
                            <Checkbox
                                onChange={({detail}) =>
                                    setNoHashtags(detail.checked)
                                }
                                checked={isNoHashtags}
                                description='Filter messages containing hashtags.'
                            >
                                Enable Hashtags Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Anti-Flood">
                            <Checkbox
                                onChange={({detail}) =>
                                    setAntiflood(detail.checked)
                                }
                                checked={isAntiflood}
                                description='Limit frequent messages (3 per 20 seconds).'
                            >
                                Enable Anti-Flood Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="NSFW Images">
                            <Checkbox
                                onChange={({detail}) =>
                                    setImageFilter(detail.checked)
                                }
                                checked={isImageFilter}
                                description='Removes pornographic images.'
                            >
                                Enable NSFW Image Filter
                            </Checkbox>
                        </FormField>

                        <FormField label="Profanity">
                            <Checkbox
                                onChange={({detail}) =>
                                    setProfanity(detail.checked)
                                }
                                checked={isProfanity}
                                description='Check for offensive language (English).'
                            >
                                Enable Profanity Filter
                            </Checkbox>
                        </FormField>

                        <FormField
                            description="Filter messages containing words from blacklist (comma-separated)"
                            label="Blacklist"
                        >
                            <Textarea
                                value={blacklist}
                                onChange={event =>
                                    setBlacklist(event.detail.value)
                                }
                            />
                        </FormField>

                        <FormField
                            description="Safe for your group domains which will be ignored by the bot (comma-separated)"
                            label="Whitelist"
                        >
                            <Textarea
                                value={whitelist}
                                onChange={event =>
                                    setWhitelist(event.detail.value)
                                }
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    )
}