import {
    Container,
    ContentLayout,
    Header,
} from "@cloudscape-design/components";
import {Link} from "react-router-dom";

// import { Table, Column } from "@cloudscape-design/components";

import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header2 from "@cloudscape-design/components/header";
import {Helmet} from "react-helmet";

import {UserProfile} from "../context/UserProfile";
import {useContext} from "react";

// import {Link} from "react-router-dom";
export default function Chats() {
    const {userProfile} = useContext(UserProfile);

    const data = [
        {group_id: 123, group_name: "Data Science Chat"},
        {group_id: 234, group_name: "Bot Research"},
    ];

    return (
        <ContentLayout header={<Header variant="h1">Your chats</Header>}>
            <Helmet>
                <title>Chats page</title>
            </Helmet>
            <Container
                header={
                    <Header variant="h2" description="You can manage bot settings in these groups">
                        Your groups:
                    </Header>
                }
            >
                <div className="groupListContent">
                    <p>{`User ID: ${userProfile.userId}`}</p>
                    <Table
                        columnDefinitions={[
                            {
                                id: "description",
                                header: "Group name",
                                cell: (item: any) => <Link
                                    to={`/chats/${item.group_id}`}>{item.group_name || "-"}</Link>,
                            },
                            {
                                id: "variable",
                                header: "Group ID",
                                cell: (item: any) => item.group_id || "-",
                                isRowHeader: true,
                            },

                        ]}
                        items={data}
                        loadingText="Loading resources"
                        sortingDisabled
                        variant="embedded"
                        empty={
                            <Box
                                margin={{vertical: "xs"}}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No group</b>
                                    <Button>Add bot in group</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header={<Header2> List of your groups: </Header2>}
                    />
                </div>
            </Container>
        </ContentLayout>
    );
}
